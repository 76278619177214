import React from "react";
import b1 from "./images/bl1.jpg";
import b2 from "./images/bl2.jpg";
import b3 from "./images/tm1.jpg";
import b4 from "./images/02.jpg";

const Blog = () => {
  return (
    <div className="home-cont">
      <div className="container-fluid sldddd">
        <div className="container mx-auto px-4 tmon">
          <div className="row hed">
            <h2 className="text-3xl font-bold">NGO Questions</h2>
          </div>

          <div className="flex flex-wrap -mx-4">
            <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
              <img
                className="w-32 h-32 object-cover rounded-full mr-4"
                src={b2}
                alt="Testimonial 1"
              />

              <div className="flex items-center mb-4">
                <div>
                  <b className="text-lg"> How to Start an NGO in India?</b>
                  <p className="text-gray-700">
                    To start an NGO in India, you need to follow a few key
                    steps, including conducting thorough research, defining your
                    mission and objectives, registering your NGO under the
                    appropriate legal structure, obtaining necessary licenses
                    and permissions, and building a strong network of supporters
                    and volunteers.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
              <img
                className="w-32 h-32 object-cover rounded-full mr-4"
                src={b1}
                alt="Testimonial 2"
              />

              <div className="flex items-center mb-4">
                <b className="text-lg">How to Register NGO in India?</b>
                <p className="text-gray-700">
                  Registering an NGO in India involves choosing a suitable legal
                  structure, such as a Trust, Society, or Section 8 Company,
                  preparing the required documents, submitting the application
                  to the appropriate authority, and fulfilling any additional
                  requirements specified by the government.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
            <img
              className="w-32 h-32 object-cover rounded-full mr-4"
              src={b4}
              alt="Testimonial 3"
            />
            <div className="flex items-center mb-4">
              <div>
                <b className="text-lg">How Many NGOs in India?</b>
                <p className="text-gray-700">
                  India is home to a large number of NGOs, with estimates
                  ranging from several hundred thousand to over a million. The
                  exact number may vary over time due to new registrations,
                  closures, and mergers.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
            <img
              className="w-32 h-32 object-cover rounded-full mr-4"
              src={b2}
              alt="Testimonial 1"
            />
            <div className="flex items-center mb-4">
              <div>
                <b className="text-lg">How to Form an NGO in India:</b>
                <p className="text-gray-700">
                  Forming an NGO in India requires careful planning, legal
                  compliance, and community engagement. Key steps include
                  defining your organization's mission and objectives, selecting
                  a suitable legal structure, registering with the relevant
                  authorities, and establishing governance and management
                  structures.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
            <img
              className="w-32 h-32 object-cover rounded-full mr-4"
              src={b1}
              alt="Testimonial 2"
            />
            <div className="flex items-center mb-4">
              <div>
                <b className="text-lg">How to Join NGO in India:</b>
                <p className="text-gray-700">
                  To join an NGO in India, you can start by researching
                  organizations that align with your interests and values,
                  reaching out to them to inquire about volunteer or employment
                  opportunities, attending events and workshops, and actively
                  participating in their activities.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
            <img
              className="w-32 h-32 object-cover rounded-full mr-4"
              src={b4}
              alt="Testimonial 3"
            />
            <div className="flex items-center mb-4">
              <div>
                <b className="text-lg">
                  How to Check Authenticity of NGO in India:
                </b>
                <p className="text-gray-700">
                  You can verify the authenticity of an NGO in India by checking
                  its registration status with the government authorities,
                  reviewing its financial reports and annual returns, evaluating
                  its track record and reputation, and seeking recommendations
                  from trusted sources or individuals.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
            <img
              className="w-32 h-32 object-cover rounded-full mr-4"
              src={b3}
              alt="Testimonial 4"
            />
            <div className="flex items-center mb-4">
              <div>
                <b className="text-lg">How to Get Funds for NGO in India:</b>
                <p className="text-gray-700">
                  NGOs in India can raise funds through various channels,
                  including grants from government agencies, corporate
                  sponsorships, individual donations, fundraising events,
                  crowdfunding platforms, and partnerships with other
                  organizations. Developing a clear fundraising strategy and
                  effectively communicating your organization's impact can
                  attract donors and supporters.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
            <img
              className="w-32 h-32 object-cover rounded-full mr-4"
              src={b2}
              alt="Testimonial 5"
            />
            <div className="flex items-center mb-4">
              <div>
                <b className="text-lg">How to Raise Funds for NGO in India:</b>
                <p className="text-gray-700">
                  Raising funds for an NGO in India requires a proactive
                  approach, including diversifying revenue streams, leveraging
                  social media and digital platforms for fundraising campaigns,
                  building relationships with donors and supporters, and
                  demonstrating transparency and accountability in financial
                  management.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
            <img
              className="w-32 h-32 object-cover rounded-full mr-4"
              src={b1}
              alt="Testimonial 6"
            />
            <div className="flex items-center mb-4">
              <div>
                <b className="text-lg">How to Start an NGO in India PDF:</b>
                <p className="text-gray-700">
                  Starting an NGO in India PDFs can provide valuable guidance
                  and resources on the legal, procedural, and operational
                  aspects of establishing and managing an NGO. You can find
                  comprehensive guides and manuals available online or through
                  government websites, nonprofit organizations, and legal
                  advisory services.
                </p>
              </div>
            </div>
          </div>
          <h2>How to Start an NGO in India:</h2>
          <h3>
            Conduct thorough research, define mission, register under suitable
            legal structure, obtain licenses, build support network.
          </h3>

          <h2>How to Register NGO in India:</h2>
          <h3>
            Choose legal structure, prepare documents, submit application,
            fulfill additional requirements.
          </h3>

          <h2>How Many NGOs in India:</h2>
          <h3>
            Estimated hundreds of thousands to over a million; fluctuates due to
            registrations, closures, mergers.
          </h3>

          <h2>How to Form an NGO in India:</h2>
          <h3>
            Plan carefully, comply with laws, register, establish governance.
          </h3>

          <h2>How to Join NGO in India:</h2>
          <h3>Research, inquire about opportunities, participate actively.</h3>
          <img
              className="w-32 h-32 object-cover rounded-full mr-4"
              src={b2}
              alt="Testimonial 6"
            />
          <h2>How to Check Authenticity of NGO in India:</h2>
          <h3>
            Verify registration, review financial reports, evaluate track
            record.
          </h3>

          <h2>How to Get Funds for NGO in India:</h2>
          <h3>
            Utilize grants, sponsorships, donations, events, crowdfunding,
            partnerships.
          </h3>

          <h2>How to Raise Funds for NGO in India:</h2>
          <h3>
            Diversify revenue streams, leverage digital platforms, build
            relationships.
          </h3>

          <h2>How to Start an NGO in India PDF:</h2>
          <h3>
            Utilize available guides, manuals online or from government, NGOs,
            legal services.
          </h3>

          <h2>How Can I Open NGO in India:</h2>
          <h3>
            Fulfill legal requirements, define mission, mobilize resources
            effectively.
          </h3>

          <h2>What is NGO in India:</h2>
          <h3>
            Independent nonprofit addressing social, economic, environmental
            issues for public good.
          </h3>

          <h2>What Documents Required for NGO Registration in India:</h2>
          <h3>Identity, address proofs, MOA, AOA, declarations.</h3>

          <h2>What is the Process to Start NGO in India:</h2>
          <h3>Assess needs, choose structure, register, establish systems.</h3>

          <h2>What is the Process of NGO Registration in India:</h2>
          <h3>
            Varies based on chosen structure, involves document submission,
            approvals.
          </h3>

          <h2>What is Meant by NGO in India:</h2>
          <h3>
            Non-Governmental Organization working for social welfare
            independently.
          </h3>

          <h2>What is the Work of NGO in India:</h2>
          <h3>
            Diverse activities like social services, education, empowerment,
            advocacy.
          </h3>

          <h2>What Does NGO Do in India:</h2>
          <h3>
            Addresses social, economic, environmental issues through various
            programs.
          </h3>
          <img
              className="w-32 h-32 object-cover rounded-full mr-4"
              src={b1}
              alt="Testimonial 6"
            />
          <h2>
            What is the Role of NGO in Improvement of Condition of Women in
            India:
          </h2>
          <h3>Empowers women through education, healthcare.</h3>

          <h2>When NGO Started in India:</h2>
          <h3>
            Modern sector emerged during colonial period, gained momentum after
            independence.
          </h3>

          <h2>Which is the Biggest NGO in India:</h2>
          <h3>No single largest NGO; depends on scope, reach, funding.</h3>

          <h2>Which is the Best NGO in India:</h2>
          <h3>Subjective, depends on mission, effectiveness, transparency.</h3>

          <h2>Which is the Richest NGO in India:</h2>
          <h3>
            Depends on revenue, assets; several large NGOs have significant
            resources.
          </h3>

          <h2>Why NGO is Needed:</h2>
          <h3>
            Address social, economic, environmental challenges, complement
            government efforts.
          </h3>

          <h2>Why NGO:</h2>
          <h3>
            Essential for social development, humanitarian causes, promoting
            social justice.
          </h3>

          <h2>Why Are NGO Important:</h2>
          <h3>
            Address inequalities, promote human rights, foster civic engagement.
          </h3>

          <h2>Why Do You Want to Work in NGO:</h2>
          <h3>
            Make meaningful impact, contribute to social change, address issues.
          </h3>

          <h2>Can NRI Start NGO in India:</h2>
          <h3>Yes, fulfill legal requirements, comply with regulations.</h3>

          <h2>Can Foreigners Start NGO in India:</h2>
          <h3>Yes, partner with Indian citizens, follow regulations.</h3>

          <h2>Can OCI Start NGO in India:</h2>
          <h3>
            Yes, fulfill legal requirements, appoint local representatives.
          </h3>
          <h1>
            **Exploring NGOs in India: A Comprehensive Guide** Are you curious
            about Non-Governmental Organizations (NGOs) in India and their
            impact on society? In this blog post, we delve into the world of
            NGOs, covering various aspects from their formation and registration
            to their role in addressing critical issues and providing employment
            opportunities. **NGO Formation and Registration Process** Starting
            an NGO in India involves careful planning and legal compliance. From
            defining the mission and objectives to choosing the appropriate
            legal structure, such as a Trust, Society, or Section 8 Company,
            each step plays a crucial role. We also discuss the necessary
            documents required for NGO registration and the process involved in
            obtaining registration. **NGOs Across Different Cities** Whether
            you're in Farrukhabad, Lucknow, or Kanpur, we explore the presence
            of NGOs in various cities. From providing educational support to
            addressing animal welfare issues, NGOs play a significant role in
            serving communities across India. **NGO Job Opportunities** For
            those interested in pursuing a career in the NGO sector, we provide
            insights into available job opportunities. From entry-level
            positions to specialized roles, we discuss NGO job openings in
            different sectors and regions, including Kenya, Ethiopia, Nigeria,
            Uganda, and Zimbabwe. **Understanding the Full Form of NGO** Ever
            wondered what "NGO" stands for? We break down the full form of NGO
            and <img
              className="w-32 h-32 object-cover rounded-full mr-4"
              src={b3}
              alt="Testimonial 6"
            />explore its significance in the context of social development
            and humanitarian causes. Whether it's in English or Hindi, we've got
            you covered with the complete explanation. **NGO Darpan: A Portal
            for NGOs** NGO Darpan is a government portal designed to facilitate
            registration and monitoring of NGOs in India. We provide an overview
            of the portal's features, including registration procedures, login
            details, and search functionalities. **NGO Websites and Resources**
            Looking for reliable NGO websites and resources? We highlight some
            of the best NGO websites in India, offering valuable information,
            job listings, and volunteer opportunities. Whether you're seeking
            information on NGO registration or exploring job prospects, these
            websites are your go-to source. **NGO Registration: Rules and
            Regulations** Navigating the rules and regulations for NGO
            registration can be daunting. We simplify the process by outlining
            the necessary steps, required documents, and associated costs.
            Whether you're planning to start an NGO or seeking renewal of
            registration, our guide provides clarity on the procedures involved.
            **NGOs Working for Environmental Conservation** Environmental
            conservation is a pressing issue, and NGOs play a pivotal role in
            addressing it. We highlight the contributions of NGOs working in
            this field, including their initiatives, partnerships, and impact on
            sustainable development.<img
              className="w-32 h-32 object-cover rounded-full mr-4"
              src={b2}
              alt="Testimonial 6"
            /> **Conclusion** NGOs are instrumental in
            driving positive change and addressing societal challenges. Whether
            it's promoting education, healthcare, or environmental
            sustainability, NGOs continue to make a significant difference in
            the lives of people across India. Join us as we explore the diverse
            world of NGOs and their invaluable contributions to society.
          </h1>
          {/* Add the remaining content blocks similarly */}
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
          <img className="w-full" src={b3} alt="ngo-Imgage" />
        </div>
      </div>
    </div>
  );
};

export default Blog;
