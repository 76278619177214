import React, { useState, useEffect } from 'react';
import axios from 'axios';
import bg from './images/bg.jpg'; // Replace with your default image path
import "./styles/newsblog.css"
const NewsBlog = () => {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = 'fobQRzjo5XXmzhAuKa2RpW63CNDYjbcHLK-zGcITgbR_wCzO';
        const ngoNewsUrl = `https://api.currentsapi.services/v1/latest-news?language=hi&category=ngo&apiKey=${apiKey}`;

        const ngoResponse = await axios.get(ngoNewsUrl);

        if (ngoResponse.status === 200) {
          const ngoArticles = ngoResponse.data.news.slice(0, 70).map(article => ({
            ...article,
            image: article.image ? article.image : bg // Replace with your default image path
          }));

          setNewsData(ngoArticles);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching NGO news:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="mx-auto max-w-2xl p-6 bg-white shadow-md rounded-md">
      <h2 className="text-xl font-bold mb-4">Latest News</h2>
      <div>
        {newsData.map((article, index) => (
          <div key={index} className="mb-6">
            <h3 className="text-xl font-semibold mb-2">{article.title}</h3>
            <img src={article.image} alt={article.title} className="w-full h-64 object-cover mb-2 rounded-md" />
            <p className="text-gray-700">{article.description}</p>
            <a href={article.url} target="_blank" rel="noopener noreferrer" className=" color: bluetext-blue-500 hover:underline block mt-2">
              Read More
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};


export default NewsBlog;

