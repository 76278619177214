// NgoFamily.jsx

import React from "react";
import "./styles/ngofamily.css"; // Import the CSS file
// Import images dynamically using import statements
import p1 from "./ImageGallery/p1.jpeg";
import p2 from "./ImageGallery/p2.jpeg";
import p3 from "./ImageGallery/p3.jpeg";
import p4 from "./ImageGallery/p4.jpeg";
import p5 from "./ImageGallery/p5.jpeg";
import p6 from "./ImageGallery/p6.jpeg";
import p7 from "./ImageGallery/p7.jpeg";
import p8 from "./ImageGallery/p8.jpeg";
import p9 from "./ImageGallery/p9.jpeg";
import p10 from "./ImageGallery/p10.jpeg";
import p11 from "./ImageGallery/p11.jpeg";
import p12 from "./ImageGallery/p12.jpeg";
import p13 from "./ImageGallery/p13.jpeg";
import p14 from "./ImageGallery/p14.jpeg";
import p15 from "./ImageGallery/p15.jpeg";
import p16 from "./ImageGallery/p16.jpeg";
import p17 from "./ImageGallery/p17.jpeg";
import p18 from "./ImageGallery/p18.jpeg";
import p19 from "./ImageGallery/p19.jpeg";
import p20 from "./ImageGallery/p20.jpeg";
import p21 from "./ImageGallery/p21.jpeg";
import p22 from "./ImageGallery/p22.jpeg";
import p23 from "./ImageGallery/p23.jpeg";
// Generate import statements dynamically for images p8 to p23
// const photos = Array.from({ length: 16 }, (_, index) => require(`./ImageGallery/p${8 + index}.jpeg`));

const NgoFamily = () => {
  const photos = [
    p1,
    p2,
    p3,
    p4,
    p5,
    p6,
    p7,
    p8,
    p9,
    p10,
    p11,
    p12,
    p13,
    p14,
    p15,
    p16,
    p17,
    p18,
    p19,
    p20,
    p21,
    p22,
    p23,
  ];
  return (
    <div className="ngo-family">
      <h2>
        हमारा एनजीओ परिवार एक साथ आत्मनिर्भरता, सशक्तिकरण और सामाजिक समृद्धि की
        ओर बढ़ रहा है। इन सुंदर तस्वीरों में हमारे सदस्यों का मिलन है, जो साझा
        की गई संघर्षों और सफलताओं का परिचय करते हैं। यह परिवार एक बड़े परिवार की
        भावना को दर्शाता है जो आपसी सहयोग और समर्थन से सहयोग करता है।
      </h2>
      <div className="photo-container">
        {photos.map((photo, index) => (
          <img key={index} src={photo} alt={`Family Photos ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default NgoFamily;
