import React from "react";
import { Link } from "react-router-dom";
import "./styles/footer.css";
import whatsappIcon from "./images/whatsapplogo.png";
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library

function Footer() {
  return (
    <footer>
      <div>
        <Helmet>
          <title>निस्वार्थ प्रयास | एक कदम मानवता की ओर</title>
          <meta
            name="description"
            content="Ngo in farrukhabad,ngo in kanpur,ngo in uttarpradesh,farrukhabad ngo, ngo in UP, ngo"
          />
          <meta
            property="og:title"
            content="NiswarthPrays: Helping the needy in Farrukhabad and Kanpur Ngo in farrukhabad kanpur,farrukhabad ngo in uttarpradesh ngo in UP ngo"
          />
          <meta
            property="og:description"
            content="NiswarthPrays is a non-profit organization that provides food, shelter, and education to the needy in Farrukhabad and Kanpur, India.Ngo in farrukhabad kanpur,farrukhabad ngo in uttarpradesh ngo in UP ngo"
          />
          <meta
            property="og:image"
            content="https://farrukhabadngo.com/fevicon.ico"
          />
        </Helmet>

        <h1>निस्वार्थ प्रयास</h1>
        {/* <Link to="/"><h1>Carpentry</h1></Link> */}
        <p>
          नैतिक इच्छाशक्ति, समाज सेवा और व्यक्तिगत विकास के लिए एक सामाजिक संगठन
          जैसे एनजीओ में शामिल होना व्यक्तियों के लिए एक महत्वपूर्ण और सार्थक
          कदम होता है। इससे आप समाज में सक्रिय भूमिका निभाते हैं और विभिन्न
          क्षेत्रों में सेवा प्रदान करके उसमें सहायता करते हैं, जैसे शिक्षा,
          स्वास्थ्य, आपदा से प्रभावित क्षेत्रों में मदद, और सामुदायिक विकास।
        </p>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/india-ngo-about">About Us</Link>
          </li>
          <li>
            <Link to="/india-ngo-services">Services</Link>
          </li>
          <li>
            <Link to="/india-ngo-contact">Contact Us</Link>
          </li>
          <li>
            <Link to="https://furniturefarrukhabad.com/">Furniture</Link>
          </li>
          <li>
            <Link to="https://repairtechies.in/">Repair</Link>
          </li>

        </ul>
      </div>
      <div>
        <h2>Contact Information</h2>
        <p>
          Address: Rampur Chilsara Road, 209625,
          <Link to={"/Farrukhabad"}>Farrukhabad</Link>,
          <Link to={"https://en.wikipedia.org/wiki/Kanpur"}>Kanpur</Link>,
          <Link to={"https://en.wikipedia.org/wiki/India"}>India</Link>
          <br />
          <span style={{ fontSize: "medium", fontWeight: "bold" }}>
            Whatsapp No:{" "}
            <img
              src={whatsappIcon}
              alt="WhatsApp Icon"
              style={{
                verticalAlign: "middle",
                marginRight: "8px",
                height: "20px",
                display: "inline-block",
              }}
            />{" "}
            8173893121
          </span>
          <br />
          Phone: 7985935817
          <br />
          Email: nirotamsingh322@gmail.com
        </p>
      </div>

      <div>
        <h2>Follow Us</h2>
        <ul className="social-links">
          <li>
            <a
              href="facebook:https://m.facebook.com/nirottamSinghrajput81"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://in.linkedin.com/company/mantrai"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
      <div>
        <br></br>
        <p>
          Developed By <li>
            <a
              href="https://in.linkedin.com/company/mantrai"
              target="_blank"
              rel="noopener noreferrer"
              color="blue"
            >
              MantrAI
            </a>
          </li>{" "}
          <span style={{ fontSize: "medium", fontWeight: "bold" }}>
            <img
              src={whatsappIcon}
              alt="WhatsApp Icon"
              style={{
                verticalAlign: "middle",
                marginRight: "8px",
                height: "20px",
                display: "inline-block",
              }}
            />{" "}
            8542832268
          </span>
        </p>
        <p>
          &copy; {new Date().getFullYear()} निस्वार्थ प्रयास. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
