import React, { useState } from 'react';
import axios from 'axios';
import config from './config'; // Import your Dialogflow API configuration

const Chatbot = () => {
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');

  // Send the user's question to the Dialogflow API
  const handleQuestionSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(config.dialogflowApiUrl, {
        query: question,
        lang: 'en',
        sessionId: config.sessionId,
      });
      setResponse(res.data.result.fulfillment.speech);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="p-4"> {/* Applying padding to the container */}
    <form onSubmit={handleQuestionSubmit} className="mb-4">
      <input
        type="text"
        value={question}
        onChange={(event) => setQuestion(event.target.value)}
        className="p-2 border rounded"
      />
      <button type="submit" className="ml-2 bg-blue-500 text-white px-4 py-2 rounded">
        Send
      </button>
    </form>
    <div className="text-gray-800">{response}</div>
  </div>
);
};

export default Chatbot;