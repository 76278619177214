import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  const loginWithCustomOptions = () => {
    loginWithRedirect({
      screen_hint: "/admin-page", // This forces login screen only
    });
  };

  return (
    <div className="login-page">
      <h2>Login Page</h2>
      <p>Only admin users can log in.</p>
      <button className="login-button" onClick={() => loginWithCustomOptions()}>
        Log In
      </button>
    </div>
  );
};

export default LoginPage;
