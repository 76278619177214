import React from "react";
import "./styles/services.css"; // Don't forget to import your CSS file
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library
import a1 from "./ServiceImages/im1.jpeg";
import a2 from "./ServiceImages/im2.jpeg";
import a3 from "./ServiceImages/im3.jpeg";
import a4 from "./ServiceImages/im4.jpeg";
import a5 from "./ServiceImages/im5.jpeg";
import a6 from "./ServiceImages/im6.jpeg";
import a7 from "./ServiceImages/im7.jpeg";
import a8 from "./ServiceImages/im8.jpeg";

function Services() {
  return (
    <div className="services-container">
      <Helmet>
        <title>
          NiswarthPrays: Helping the needy in Farrukhabad, Kanpur, Uttar Pradesh
        </title>
        <meta
          name="description"
          content="Ngo in farrukhabad,ngo in kanpur,ngo in uttarpradesh,farrukhabad ngo, ngo in UP, ngo"
        />
        <meta
          property="og:title"
          content="NiswarthPrays: Helping the needy in Farrukhabad and Kanpur Ngo in farrukhabad kanpur,farrukhabad ngo in uttarpradesh ngo in UP ngo"
        />
        <meta
          property="og:description"
          content="NiswarthPrays is a non-profit organization that provides food, shelter, and education to the needy in Farrukhabad and Kanpur, India.Ngo in farrukhabad kanpur,farrukhabad ngo in uttarpradesh ngo in UP ngo"
        />
        <meta
          property="og:image"
          content="https://farrukhabadngo.com/fevicon.ico"
        />
      </Helmet>
      <section className="hero-section">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
            <img
              src={a1}
              alt="ngo-free-coaching-english-maths"
              className="w-auto h-auto mx-auto block rounded-md"
            />
            <h2 className="text-xl font-bold mb-2">समाज सेवा सहायक</h2>
            <p className="text-gray-700">
            संघर्ष संदेशों को साझा करते हुए, समूहित अभियानों की योजना बनाते हुए एकत्रित हुए।
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
            <img
              src={a2}
              alt="ngo-free-help-Disaster-Relief"
              className="w-full h-auto mb-4 rounded-md"
            />
            <h2 className="text-xl font-bold mb-2">
            दुखद संवेदना
            </h2>
            <p className="text-gray-700">
            हम साझा करते हैं इस दुख को, जो हमारे साथी की मृत्यु ने हमें दिया है। हम समर्थन और संवेदना के साथ उनके परिवार के साथ हैं।
            </p>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
            <img
              src={a4}
              alt="ngo-free-help-fair-mela-organize"
              className="w-full h-auto mb-4 rounded-md"
            />
            <h2 className="text-xl font-bold mb-2">दुखद घड़ी में</h2>
            <p className="text-gray-700">
            माननीय विधायक जी के साथ इस दुखद घड़ी में
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
            <img
              src={a5}
              alt="ngo-free-help-Disaster-Relief"
              className="w-full h-auto mb-4 rounded-md"
            />
            <h2 className="text-xl font-bold mb-2">समाज में जागरूकता</h2>
            <p className="text-gray-700">
            NGO के माध्यम से जागरूकता फैलाना हमारा मिशन है, समाज में बदलाव लाने के लिए जागरूकता और शिक्षा को बढ़ावा देना हमारा उद्देश्य है।
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
            <img
              src={a6}
              alt="ngo-free-help-rural-work"
              className="w-full h-auto mb-4 rounded-md"
            />
            <h2 className="text-xl font-bold mb-2">कपड़े वितरण</h2>
            <p className="text-gray-700">
            कपड़ों का वितरण एक महत्वपूर्ण सेवा है जो आपदा से प्रभावित क्षेत्रों और जरूरतमंद लोगों की मदद के लिए की जाती है। 
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
            <img
              src={a7}
              alt="ngo-free-help-women-empowerment"
              className="w-full h-auto mb-4 rounded-md"
            />
            <h2 className="text-xl font-bold mb-2">सशक्तिकरण</h2>
            <p className="text-gray-700">
              सशक्तिकरण समाज में समानता और सक्षमता को प्राप्त करने की
              प्रक्रिया है। इससे समाज को उनकी अधिकारों की सम्मानित
              पहचान मिलती है।
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
            <img
              src={a8}
              alt="ngo-free-help-rural-deevelopment"
              className="w-full h-auto mb-4 rounded-md"
            />
            <h2 className="text-xl font-bold mb-2">सामाजिक कार्यक्रम</h2>
            <p className="text-gray-700">
            हम ग्रामीण क्षेत्रों में स्थायी परिवर्तन लाने के लिए परियोजनाओं पर काम करते हैं। हम सुनिश्चित करना चाहते हैं कि गांवों में सामाजिक, आर्थिक और आधारिक सुविधाओं में सुधार हो।
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
