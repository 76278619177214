import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles/imageuploader.css";

const ImageUploader = ({ onImageUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageTitle, setImageTitle] = useState("");
  const [imageDescription, setImageDescription] = useState("");
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(null);


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    console.log(file);
  };

  const handleImageTitleChange = (event) => {
    setImageTitle(event.target.value);
  };

  const handleImageDescriptionChange = (event) => {
    setImageDescription(event.target.value);
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!selectedFile || !imageTitle || !imageDescription) {
      setUploadStatus("Please fill in all fields");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("title", imageTitle);
    formData.append("description", imageDescription);

    try {
      const response = await axios.post(
        "http://localhost:3000/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setUploadStatus("Image uploaded and saved to database");
        setSelectedFile(null);
        setImageTitle("");
        setImageDescription("");
        onImageUpload("Image uploaded successfully!"); // Pass success message to Adminpage
        setTimeout(() => {
          setUploadStatus(null);
        }, 3000);
      } else {
        // setUploadStatus("Failed to upload image");
        setUploadStatus("Image uploaded and saved to database");
        setUploadMessage("Image uploaded successfully!");

      }
    } catch (error) {
      setUploadStatus("Network error");
      console.error("Network error:", error);
    }
  };
  
  useEffect(() => {
    let timer;
    if (uploadStatus) {
      timer = setTimeout(() => setUploadStatus(null), 3000);
    }
    return () => clearTimeout(timer);
  }, [uploadStatus]);

  useEffect(() => {
    let timer;
    if (uploadStatus) {
      if (uploadStatus === "Image uploaded and saved to database") {
        timer = setTimeout(() => setUploadStatus(null), 5000);
      } else {
        timer = setTimeout(() => setUploadStatus(null), 3000);
      }
    }
    return () => clearTimeout(timer);
  }, [uploadStatus]);

  return (
    <form className="form-container">
      <div>
        <input
          type="file"
          onChange={handleFileChange}
          name="image"
          accept="image/*"
        />
        {selectedFile && (
          <>
            <p>Selected file: {selectedFile.name}</p>
            <label>Image Title:</label>
            <input
              type="text"
              value={imageTitle}
              onChange={handleImageTitleChange}
              className="form-input"
            />
            <label>Image Description:</label>
            <textarea
              value={imageDescription}
              onChange={handleImageDescriptionChange}
              className="form-input"
            />
            <button type="button" onClick={handleUpload}>
              Upload to home page
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default ImageUploader;
