import React, { useEffect } from "react";
import "./styles/sellbuy.css";
import whatsappIcon from "./images/whatsapplogo.jpg";
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library

const SellBuy = () => {
  // Scroll to the top of the page when the component mounts (or loads) after the redirect
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sell-buy-section">
      <Helmet>
        <title>निस्वार्थ प्रयास | एक कदम मानवता की ओर</title>
        <meta
          name="description"
          content="Ngo in farrukhabad,ngo in kanpur,ngo in uttarpradesh,farrukhabad ngo, ngo in UP, ngo"
        />
        <meta
          property="og:title"
          content="NiswarthPrays: Helping the needy in Farrukhabad and Kanpur Ngo in farrukhabad kanpur,farrukhabad ngo in uttarpradesh ngo in UP ngo"
        />
        <meta
          property="og:description"
          content="NiswarthPrays is a non-profit organization that provides food, shelter, and education to the needy in Farrukhabad and Kanpur, India.Ngo in farrukhabad kanpur,farrukhabad ngo in uttarpradesh ngo in UP ngo"
        />
        <meta
          property="og:image"
          content="https://farrukhabadngo.com/fevicon.ico"
        />
      </Helmet>
      <h2 className="tt font-bold">Join Us</h2>
      <p>
        नैतिक इच्छाशक्ति, समाज सेवा और समृद्धि के प्रति समर्पण - ये सिद्धांत
        हमारे एनजीओ के मूल आधार हैं। हमारा एनजीओ निश्वार्थ प्रयास, समाज में
        सकारात्मक बदलाव लाने और समृद्धि के संकल्प से जुड़ा हुआ है।
      </p>

      {/* Contact Card */}
      {/* contactinfo card */}
      <div className="contactinfo">
        <h1>Contact Us</h1>
        <p>
          Mobile: <a href="tel:​8173893121">​8173893121</a>
        </p>
        <p>
          Whatsapp No:{" "}
          <img
            src={whatsappIcon}
            alt="WhatsApp Icon"
            style={{
              verticalAlign: "middle",
              marginRight: "8px",
              height: "20px",
              display: "inline-block",
            }}
          />{" "}
          <a href="https://wa.me/8542832268">7985935817</a>
        </p>
        <p className="contact-email">
          You can Mail us:{" "}
          <a href="mailto:nirotamsingh322@gmail.com ">
            nishwarthaprays@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default SellBuy;
