import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./styles/adminpage.css"; // Import the CSS file for Adminpage styling
import ImageUploader from "./ImageUploader";

const Adminpage = () => {
  const [uploadMessage, setUploadMessage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);


  const handleImageUpload = (message) => {
    setUploadMessage(message);
    // Optionally, perform other operations with the uploaded image
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { user, isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  const userName = user.name.split("@")[0];
  const firstName = userName.split(".")[0];

  return (
    <div>
      <div className="admin-container">
        {isAuthenticated && (
          <div className="user-info">
            <img src={user.picture} alt={user.name} />
            <h2>Welcome 🙏🏼 {firstName}</h2>
          </div>
        )}
      </div>
      <div className="admin-container">
      <h1>Admin Page</h1>
        {uploadMessage && <h1>{uploadMessage}</h1>} {/* Display the upload message */}
      </div>
      <ImageUploader onImageUpload={handleImageUpload} />
    </div>
  );
};

export default Adminpage;
