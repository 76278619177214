import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import "./styles/adminpage.css"; // Import the CSS files
// require("dotenv").config(); // Load variables from .env file
// const SERVER_URL = process.env.SERVER_URL || 'default_server_url';

const Admin = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageTitle, setImageTitle] = useState("");
  const [imageDescription, setImageDescription] = useState("");
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const { user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    console.log(file);
  };

  const handleImageTitleChange = (event) => {
    setImageTitle(event.target.value);
  };

  const handleImageDescriptionChange = (event) => {
    setImageDescription(event.target.value);
  };

  const handleImageUpload = async () => {
    if (!selectedFile || !imageTitle || !imageDescription) {
      setUploadStatus("Please fill in all fields");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("title", imageTitle);
    formData.append("description", imageDescription);
// console.log(`${SERVER_URL}/upload`)
    try {
      const response = await axios.post(
        "https://nishwarthprayas.onrender.com/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setUploadStatus("Image uploaded failed");
        setUploadMessage("Image uploaded failed!");
      } else {
        setUploadStatus("Image uploaded and saved to database");
        setUploadMessage("Image uploaded successfully!");
        // setUploadedImage(selectedFile); // Set the uploaded image
        setSelectedFile(null);
        setImageTitle("");
        setImageDescription("");
        // Clear success messages after 3 seconds (adjust duration as needed)
        setTimeout(() => {
          setUploadStatus(null);
          setUploadMessage(null);
        }, 3000);
      }
    } catch (error) {
      setUploadStatus("Network error");
      console.error("Network error:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="admin-container">
        {isAuthenticated && (
          <div className="user-info">
            <img src={user.picture} alt={user.name} />
            <h2 className="text-xl font-bold">
              Welcome 🙏🏼 {user.name.split("@")[0].split(".")[0]}
            </h2>
          </div>
        )}
      </div>
      <div className="admin-container">
        <h1 className="text-3xl font-bold">Admin Page</h1>
      </div>
      {uploadMessage && (
        <p className="mt-4 p-2 bg-green-500  rounded-md text-center">
          {uploadMessage}
        </p>
      )}
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <form className="form-container mt-4">
          <div>
            <input
              type="file"
              onChange={handleFileChange}
              name="image"
              accept="image/*"
              className="mb-2"
            />
            {selectedFile && (
              <>
                <p className="mb-2">Selected file: {selectedFile.name}</p>
                <label className="block mb-1">Image Title:</label>
                <input
                  type="text"
                  value={imageTitle}
                  onChange={handleImageTitleChange}
                  className="form-input mb-2"
                />
                <label className="block mb-1">Image Description:</label>
                <textarea
                  value={imageDescription}
                  onChange={handleImageDescriptionChange}
                  className="form-input mb-2"
                />
                <button
                  type="button"
                  onClick={handleImageUpload}
                  className="bg-blue-500 hover:bg-blue-600 font-bold py-2 px-4 rounded"
                >
                  Upload to home page
                </button>
                <div>
                {uploadMessage && (
                  <p className="mt-4 p-2 bg-green-500 text-green-900 text-decoration-color: #0f9508  font-weight: 200 rounded-md text-center">
                    {uploadMessage}
                  </p>
                )}
                </div>
              </>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default Admin;
