// Contact.js
import React, { useState, useEffect } from "react";
import "./styles/contact.css";
import whatsappIcon from "./images/whatsapplogo.png";
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library

function Contact() {
  // Scroll to the top of the page when the component mounts (or loads) after the redirect
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mobile, setMobile] = useState(""); // Add mobile state
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send form data to the backend server
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        "form-name": "ContactInfo",
        name,
        email,
        message,
        mobile, // Include mobile in the form submission
      }).toString(),
    })
      .then((response) => {
        if (response.ok) {
          setSubmissionStatus("success");
          setName("");
          setEmail("");
          setMessage("");
          setMobile("");
        } else {
          setSubmissionStatus("error");
        }
      })
      .catch((error) => {
        console.error(error);
        setSubmissionStatus("error");
      });
  };
  return (
    <div className="container">
      <Helmet>
        <title>निस्वार्थ प्रयास | एक कदम मानवता की ओर</title>
        <meta
          name="description"
          content="Ngo in farrukhabad,ngo in kanpur,ngo in uttarpradesh,farrukhabad ngo, ngo in UP, ngo"
        />
        <meta
          property="og:title"
          content="NiswarthPrays: Helping the needy in Farrukhabad and Kanpur Ngo in farrukhabad kanpur,farrukhabad ngo in uttarpradesh ngo in UP ngo"
        />
        <meta
          property="og:description"
          content="NiswarthPrays is a non-profit organization that provides food, shelter, and education to the needy in Farrukhabad and Kanpur, India.Ngo in farrukhabad kanpur,farrukhabad ngo in uttarpradesh ngo in UP ngo"
        />
        <meta
          property="og:image"
          content="https://farrukhabadngo.com/fevicon.ico"
        />
      </Helmet>
      {/* contactinfo card */}
      <div className="contactinfo">
        <h1>Contact Us</h1>
        <p>
          Mobile: <a href="tel:​8173893121">​8173893121</a>
        </p>
        <p>
          Whatsapp No:{" "}
          <img
            src={whatsappIcon}
            alt="WhatsApp Icon"
            style={{
              verticalAlign: "middle",
              marginRight: "8px",
              height: "20px",
              display: "inline-block",
            }}
          />{" "}
          <a href="https://wa.me/8542832268">7985935817</a>
        </p>
        <p className="contact-email">
          You can Mail us:{" "}
          <a href="mailto:nirotamsingh322@gmail.com ">
            nishwarthaprays@gmail.com
          </a>
        </p>
      </div>
      {/* form Integration */}
      {submissionStatus === "success" && (
        <div className="success-message">Form submitted successfully!</div>
      )}
      {submissionStatus === "error" && (
        <div className="error-message">
          Error submitting the form. Please try again.
        </div>
      )}
      <form onSubmit={handleSubmit} netlify className="contact-form">
        <input
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          name="name"
          required
          className="block w-full border rounded-md py-2 px-3 mb-3 focus:outline-none focus:border-blue-500"
        />
        <input
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name="email"
          required
          className="block w-full border rounded-md py-2 px-3 mb-3 focus:outline-none focus:border-blue-500"
        />
        <input
          type="tel" // Use type 'tel' for mobile numbers
          placeholder="Your Mobile Number"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          name="mobile"
          required
          className="block w-full border rounded-md py-2 px-3 mb-3 focus:outline-none focus:border-blue-500"
        />
        <textarea
          placeholder="Your Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          name="message"
          required
          className="block w-full border rounded-md py-2 px-3 mb-3 focus:outline-none focus:border-blue-500"
        />
        <button
          type="submit"
          className=" ff bg-blue-500 hover:bg-blue-700 text-green font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default Contact;
