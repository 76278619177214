import React from "react";
import { Link } from "react-router-dom";
import "./styles/about.css"; // Import your CSS file for styling
import blankImage from "./images/prof.jpeg"; // Import a blank image for the team section
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library
import dam from "./images/dam.jpg";
function About() {
  return (
    <div className="about-container">
      <Helmet>
        <title>निस्वार्थ प्रयास | एक कदम मानवता की ओर</title>
        <meta
          name="description"
          content="Ngo in farrukhabad,ngo in kanpur,ngo in uttarpradesh,farrukhabad ngo, ngo in UP, ngo"
        />
        <meta
          property="og:title"
          content="NiswarthPrays: Helping the needy in Farrukhabad and Kanpur Ngo in farrukhabad kanpur,farrukhabad ngo in uttarpradesh ngo in UP ngo"
        />
        <meta
          property="og:description"
          content="NiswarthPrays is a non-profit organization that provides food, shelter, and education to the needy in Farrukhabad and Kanpur, India.Ngo in farrukhabad kanpur,farrukhabad ngo in uttarpradesh ngo in UP ngo"
        />
        <meta
          property="og:image"
          content="https://farrukhabadngo.com/fevicon.ico"
        />
      </Helmet>
      {/* About Us Section */}
      <section className="about-us">
        <h1 className="section-title">About Us</h1>
        <p className="section-description">
          ​निस्वार्थ प्रयास संस्थापक, नरोत्तम सिंह राजपूत, एक समाजसेवी और समाज
          सुधारक हैं। उनका सपना है एक समर्थ समाज बनाना जहां सभी लोग अच्छे जीवन
          का आनंद ले सकें। नरोत्तम सिंह राजपूत ने निस्वार्थ प्रयास की स्थापना
          करते समय समाज में समानता, शिक्षा और समुदाय सेवा के मूल मूल्यों पर
          ध्यान केंद्रित किया। उन्होंने विभिन्न क्षेत्रों में समाज के सुधार के
          लिए पहल की है, जैसे शिक्षा, आपदा से प्रभावित क्षेत्रों में मदद, महिला
          सशक्तिकरण, और गाँवों में विकास। उनका संघर्ष समाज में उदारता,
          सहानुभूति, और सहायता की भावना को बढ़ावा देने के लिए रहा है। नरोत्तम
          सिंह राजपूत का संगठन एक समृद्ध समाज की दिशा में कार्य करता है, जहां
          सभी को समान अवसर मिले और सभी की आवाज सुनी जाए। उनकी प्रेरणा और नेतृत्व
          से, निश्वार्थ प्रयास आज भी समाज को सुधारने और समृद्धि के मार्ग पर
          अग्रसर है। नरोत्तम सिंह राजपूत ने अपने व्यक्तिगत संघर्ष, संघर्ष और
          समर्थन के माध्यम से समाज के लिए निश्चित रूप से एक योगदान किया है। उनका
          सपना है समृद्ध समाज की रचना करना, जो समानता, विकास और समृद्धि के मार्ग
          पर अग्रसर हो।
        </p>
      </section>

      {/* Our Team Section */}
      <section className="our-team">
        <h2 className="section-title">Our Team</h2>
        <div className="team-members">
          {/* Individual Team Member */}
          <div className="team-member flex justify-center items-center flex-col text-center">
            <img
              src={blankImage}
              alt="Team Member 1"
              className="team-member-image w-40 h-40 rounded-full"
            />
            <h3 className="team-member-name">नरोत्तम सिंह राजपूत</h3>
            <p className="team-member-role font-bold">संस्थापक</p>
            <p className="team-member-description">
              नरोत्तम सिंह राजपूत, एक समाजसेवी और समाज सुधारक हैं। उनका सपना है
              एक समर्थ समाज बनाना जहां सभी लोग अच्छे जीवन का आनंद ले सकें।
            </p>
          </div>
        </div>
      </section>
      <section className="our-team">
        <h2 className="section-title">Our Team</h2>
        <div className="team-members">
          {/* Individual Team Member */}
          <div className="team-member flex justify-center items-center flex-col text-center">
            <img
              src={dam}
              alt="Team Member 1"
              className="team-member-image w-40 h-40 rounded-full"
            />
            <h3 className="team-member-name">दामोदर सिंह राजपूत</h3>
            <p className="team-member-role font-bold">सहायक कर्ता</p>
            <p className="team-member-description">
              दामोदर सिंह राजपूत, एक समाजसेवी और राजनीतिज्ञ हैं। वे समाज में
              सकारात्मक बदलाव और विकास को प्रोत्साहित करने के लिए सक्रिय रूप से
              काम करते हैं। उनका सपना है एक ऐसे समाज की रचना करना जहां हर
              व्यक्ति खुशहाली और समृद्धि का अनुभव कर सके।वे राजनीतिक मंच पर भी
              अपने विचारों और कार्यों से मशहूर हैं। उन्होंने अपने नेतृत्व और
              समर्थन से समाज में सुधार के लिए कई पहलूओं पर काम किया है। उन्होंने
              राजनीतिक क्षेत्र में अपने संघर्ष और समर्थन के माध्यम से सामाजिक और
              आर्थिक बदलाव को बढ़ावा दिया है
            </p>
          </div>
        </div>
      </section>

      {/* Our Values Section */}
      <section className="our-values">
        <h2 className="section-title">Our Values</h2>
        <ul className="values-list">
          <li className="value-item">
            <h3 className="value-title">समर्थ समाज</h3>
            <p className="value-description">
              हमारा संकल्प समर्थ समाज का निर्माण है, जहां सभी को समान अवसर मिले
              और सभी का सम्मान हो।
            </p>
          </li>
          <li className="value-item">
            <h3 className="value-title">साझेदारी</h3>
            <p className="value-description">
              हम विभिन्न स्तरों के साझेदारों के साथ मिलकर काम करते हैं ताकि हम
              अपने लक्ष्यों को पूरा कर सकें।
            </p>
          </li>
          <li className="value-item">
            <h3 className="value-title">समर्थन और सहायता</h3>
            <p className="value-description">
              हम अपने समुदाय की सहायता करने के लिए प्रतिबद्ध हैं और उन्हें विकास
              के मार्ग पर साथ चलने में समर्थन प्रदान करते हैं।
            </p>
          </li>
          <li className="value-item">
            <h3 className="value-title">नैतिकता</h3>
            <p className="value-description">
              हमारे कार्यों में नैतिकता, सच्चाई और ईमानदारी को सबसे महत्वपूर्ण
              मानते हैं।
            </p>
          </li>
          {/* Add more values as needed */}
        </ul>

        {/* Why We Trust You? */}
        <h2 className="section-title">Why We Trust You?</h2>
        <ul className="trust-values">
          <li>Transparency services</li>
          <li>Integrity </li>
          <li>सहयोग और साझेदारी</li>
          <li>पारदर्शिता और ज़िम्मेदारी</li>
          <li>ज़िम्मेदारीपूर्ण</li>
        </ul>
        <p className="section-description">
          यह संगठन स्वयंसेवकों और सहायक संगठनों के साथ मिलकर विभिन्न क्षेत्रों
          में कार्य करता है। एनजीओ के कार्यों में शिक्षा, स्वास्थ्य, आपदा से
          प्रभावित क्षेत्रों में मदद, महिला सशक्तिकरण, ग्रामीण विकास, वातावरण
          संरक्षण और अन्य क्षेत्र शामिल होते हैं।
        </p>
      </section>

      {/* Contact Us Section */}
      <section className="contact-us">
        <Link to="/india-ngo-contact">
          <h2 className="section-title">Contact Us</h2>
        </Link>

        <p className="section-description">
          If you have questions or need assistance, please feel free to reach
          out to us at{" "}
          <a href="mailto:nirotamsingh322@gmail.com">
            nirotamsingh322@gmail.com
          </a>
          .
        </p>
      </section>
    </div>
  );
}

export default About;
